import React from "react";
import { Comment } from '@roc-digital/types';
import { useMethod } from "./useMethod";
import { getConfig } from '@roc-digital/mxm-base/config';
import { JsonFetch } from "@roc-digital/mxm-base/logic";

interface UseComments {
  send(body: string)
  loadMore();
  comments: Comment[];
  canLoadMore?: boolean;
  isSending?: boolean;
  loading?: boolean;
  loaded?: boolean;
}

export function useComments(refId: string): UseComments {
  const pendingComments = React.useMemo(() => new Map<string, Comment>(), []);
  const canLoadMore = React.useRef(false);

  const loadQuery = useMethod(fetchComments, {
    autoRun: [refId, 0],
    map(next, current) {
      canLoadMore.current = next.result.length ? true : false;
      if (next.args[1] === 0) {
        return next;
      }
      return {
        result: [...current?.result || [], ...next.result],
        args: next.args
      };
    },
  });

  const createQuery = useMethod(createComment, {
    onBefore: async (args) => {
      const body = args[1];

      const pComment: Comment = {
        id: Math.random(),
        body: body,
        handle: '(Sending)',
        ref_id: '',
        timestamp: Date.now(),
        user_id: '',
        can_edit: false,
      }

      pendingComments.set(body, pComment);

      const updates = [
        pComment,
        ...loadQuery.getLastResult() || [],
      ];

      loadQuery.setResult(updates);

      return args;
    },
    onError: (err, args) => {
      let pComment = pendingComments.get(args[1]);
      const results = loadQuery.getLastResult() || [];
      let replaceIndex = -1;

      if (pComment) {
        replaceIndex = results.indexOf(pComment);
        pendingComments.delete(args[0]);
      }

      if(replaceIndex == -1 || !pComment) return;

      pComment = {
        ...pComment,
        handle: 'Failed to send comment!',
        error: 'Reason: ' + (err.data?.message || 'Unknown error'),
      },
      results.splice(replaceIndex, 1, pComment);
      loadQuery.setResult([...results]);

    },

    onSuccess: (newComment, args) => {
      const pComment = pendingComments.get(args[1]);
      const results = loadQuery.getLastResult() || [];
      let replaceIndex = -1;

      if (pComment) {
        replaceIndex = results.indexOf(pComment);
        pendingComments.delete(args[0])
      }

      if (replaceIndex > -1) {
        results.splice(replaceIndex, 1, newComment);
      } else {
        results.splice(0, 0, newComment);
      }

      loadQuery.setResult([...results]);
    }
  });


  return {
    comments: loadQuery.result || [],
    loadMore: () => {
      const results = loadQuery.getLastResult()
      if (!results.length) {
        loadQuery.run(refId, 0);
        return;
      }
      const lastId = results[results.length - 1].id;
      loadQuery.run(refId, lastId)
    },
    send: (body) => {
      createQuery.run(refId, body).catch(_ => {});
    },
    canLoadMore: canLoadMore.current,
    isSending: createQuery.loading,
    loading: loadQuery.loading,
    loaded: loadQuery.count > 0,
  }
}

async function createComment(refId: string, body: string): Promise<Comment> {
  const baseUrl = getConfig().mxmAuxApiUrl;

  const comment = await JsonFetch<Comment>({
    method: 'POST',
    url: `${baseUrl}/articles/${refId}/comments`,
    data: {
      body
    }
  })

  return comment;
}

async function fetchComments(refId: string, before: number): Promise<Comment[]> {
  const baseUrl = getConfig().mxmAuxApiUrl;

  const comments = await JsonFetch<Comment[]>({
    method: 'GET',
    url: `${baseUrl}/articles/${refId}/comments?before_id=${before || 0}`,
  });

  return comments;
}