import { Heading1 } from "@roc-digital/ui-web";
import { CommentInput } from "./CommentInput";
import { CommentList } from "./CommentList";
import { Comment } from "@roc-digital/types";
import { useComments } from "@roc-digital/ui-lib";
import { useAuthState } from "@/logic";
import { useNavigate } from "react-router";

const commentData: Comment[] = [
  {id: 1, body: '2', timestamp: Date.now(), handle: 'Test', ref_id: '', user_id: '', },
  {id: 1, timestamp: Date.now(), handle: 'PatriotGunner77', body: 'About time the U.S. stepped up in a big way! Ukraine has been holding the line against Russia for years now, and this package shows we’re serious about supporting our allies. Let’s just hope this doesn’t drag us into something bigger', ref_id: '', user_id: '', },
]

interface CommentsProps {
  refId?: string;
}

export function Comments(props: CommentsProps) {
  if(!props.refId) return null;

  return <ResetableContainer
    key={props.refId}
    refId={props.refId}/>
}

export function ResetableContainer(props: {refId: string}) {
  const comments = useComments(props.refId);
  const auth = useAuthState();
  const navigate = useNavigate();

  return <div className="flex flex-col gap-4 mx-2">
    <Heading1 className="dark:text-white mt-6 text-action !font-extrabold !text-[20px] !leading-9 !tracking-tight !font-chivo">
      Comments
    </Heading1>
    <CommentList
      comments={comments.comments}
      canLoadMore={comments.canLoadMore}
      loading={comments.loading}
      loaded={comments.loaded}
      onLoadMore={comments.loadMore}
    />
    <CommentInput
      onLogin={(type) => {
        if(type === 'login') {
          navigate('/login')
        } else {
          navigate('/signup')
        }
      }}
      needsLogin={!auth.authenticated}
      onShare={comments.send}
    />
  </div>
}