import { commentIcon } from "@roc-digital/ui-lib";
import { Button, ButtonSizes, Icon, IconBase, Input, TextLink } from "@roc-digital/ui-web";
import React from "react";

interface CommentInputProps {
  onShare: (body: string) => void;
  onLogin: (type: 'login' | 'signup') => void
  needsLogin?: boolean;
}

export function CommentInput(props: CommentInputProps) {
  const [body, setBody] = React.useState('');

  const valid = !props.needsLogin && body.trim()?.length;
  const share = () => {
    if(valid) {
      props.onShare(body)
      setBody('');
    }    
  }

  if(props.needsLogin) {
    return <SignupInput onLogin={props.onLogin}/>
  }

  return <div className="flex flex-row gap-2">
    <Input
      className="flex-grow"
      inputContainerClassName="flex-grow"
      placeholder="Share your opinion"
      value={body}
      onKeyUp={(event) => {
        if(event.key === 'Enter') {
          share();
        }
      }}
      changeEvent={setBody}
    />
    <Button
      size={ButtonSizes.medium}
      customClassName="rounded-lg h-[42px]"
      clickEvent={share}
      disabled={!valid}
    >Share</Button>
  </div>
}

function SignupInput(props: {onLogin: (type: 'login' | 'signup') => void}) {
  const onLogin = () => props.onLogin('login');
  const onSignup = () => props.onLogin('signup');
  return <div className="flex flex-row gap-2">
    <div className="text-[#C1C1C1] gap-4 flex flex-row items-center px-2 h-[48px] flex-grow font-workSans font-normal text-font inline-block rounded-lg border border-solid border-[#DEDEDE] dark:border-[#888888] text-font-on-secondary box-border">
      <Icon src={commentIcon} className="text-[#919193]" size="large"/>
      <span>
        Share your opinion -&nbsp;<span onClick={onLogin} className="underline hover:cursor-pointer text-[#24AAE1]">login</span>&nbsp;or&nbsp;<span onClick={onSignup} className="underline hover:cursor-pointer text-[#24AAE1]">signup</span>&nbsp;to comment.
      </span>
    </div>
  </div>
}